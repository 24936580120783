import * as moment from 'moment';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Film} from '../../models/film.model';
import {Event} from '../../models/event.model';
import {FilmAgeRatings} from '../../enums/film-age-ratings.enum';
import {FilmPresentationTypes} from '../../enums/film-presentation-types.enum';
import {FilmLanguageVersions} from '../../enums/film-language-versions.enum';
import {forkJoin} from 'rxjs';
import {v1 as UUID4} from 'uuid';
import {isUndefined} from 'util';
import {isObject} from '../../../shared/utils';
import {TranslateService} from '@ngx-translate/core';
import {TypeaheadMatch} from 'ngx-bootstrap';
import {UserService} from '../../../login/services/user.service';

@Component({
  selector: 'c360-event-film-form',
  templateUrl: './event-film.form.html'
})
export class EventFilmFormComponent implements OnInit {

  @Output() onFormSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  @Input() eventFilms: Array<Film>;
  @Input() event: Event;
  @Input() eventFilm: Film;
  @Input() formMode: string = null;

  private _filmsAsArray: Array<any>;
  private _selectedFilmItem: any;

  private _eventFilmForm: FormGroup;

  /* Enums */
  private _ageRatings = FilmAgeRatings;
  private _ageRatingsKeys;

  private _presentationTypes = FilmPresentationTypes;
  private _presentationTypesKeys;

  private _languageVersions = FilmLanguageVersions;
  private _languageVersionsKeys;

  constructor(private fb: FormBuilder,
              private _translateService: TranslateService,
              private _userService: UserService) {
  }

  ngOnInit(): void {
    if (this.formMode === 'create') {
      const translations = [];
      for (const key in this._languageVersions) {
        if (this._languageVersions.hasOwnProperty(key)) {
          translations.push(this._translateService.get(`MODUL_EVENTS.LANGUAGE_VERSION.${this._languageVersions[key]}`));
        }
      }

      forkJoin(translations)
        .subscribe(
          () => {
            this._filmsAsArray = [];
            for (const key in this.eventFilms) {
              if (this.eventFilms.hasOwnProperty(key)) {
                const film: Film = this.eventFilms[key];
                const languageVersion = this._languageVersions.hasOwnProperty(film.languageVersion) ? this._translateService.instant(`MODUL_EVENTS.LANGUAGE_VERSION.${film.languageVersion}`) : film.languageVersion;
                const name = `${film.tabTitlePresentationType} - ${languageVersion}`;
                this._filmsAsArray.push(
                  {
                    id: film.id,
                    name: name
                  }
                );
              }
            }
          }
        );
    }
    if (this.formMode == null) {
      if (this.eventFilm != null && this.eventFilm.id != null) {
        this.formMode = 'update';
      } else {
        this.formMode = 'create';
      }
    }
    /* CREATE NEW EMPTY FILM */
    if (this.formMode === 'create') {
      this.eventFilm = new Film();
      if (this.event != null) {
        this.eventFilm.eventId = this.event.id;
        if (isUndefined(this.eventFilms) || Object.keys(this.eventFilms).length === 0) {
          this.eventFilm.title = this.event.name;
        }
      }
    }

    this._ageRatingsKeys = Object.keys(this._ageRatings);
    this._presentationTypesKeys = Object.keys(this._presentationTypes);
    this._languageVersionsKeys = Object.keys(this._languageVersions);

    this.createForm();
  }

  createForm(): void {

    let idDefinition;
    if (this.formMode === 'create') {
      idDefinition = [];
    } else {
      idDefinition = [this.eventFilm.id, Validators.required];
    }
    const releaseDate = this.eventFilm.releaseDate ? moment(this.eventFilm.releaseDate) : null;
    this._eventFilmForm = this.fb.group({
      selectedFilm: [],
      id: idDefinition,
      mandatorId: [this._userService.getUser().mandatorId, Validators.required],
      eventId: [this.eventFilm.eventId, Validators.required],
      typeChildId: [this.eventFilm.typeChildId],
      title: [this.eventFilm.title, Validators.required],
      description: [this.eventFilm.description, [Validators.minLength(10)]],
      directors: [this.eventFilm.directors, []],
      actors: [this.eventFilm.actors, []],
      cameraOperators: [this.eventFilm.cameraOperators, []],
      cutters: [this.eventFilm.cutters, []],
      books: [this.eventFilm.books, []],
      runtime: [this.eventFilm.runtime, [
        Validators.min(0)
      ]],
      ageRating: [this.eventFilm.ageRating, []],
      releaseDate: [
        releaseDate ? {
          year: +releaseDate.format('YYYY'),
          month: +releaseDate.format('MM'),
          day: +releaseDate.format('DD'),
        } : null, []],
      countryOfOrigin: [this.eventFilm.countryOfOrigin, []],
      creationYear: [this.eventFilm.creationYear, [
        Validators.min(1900),
        Validators.max(2100),
      ]],
      spokenLanguage: [this.eventFilm.spokenLanguage, []],
      subtitleLanguage: [this.eventFilm.subtitleLanguage, []],
      websiteUrl: [this.eventFilm.websiteUrl, []],
      genres: [this.eventFilm.genres, []],
      publisher: [this.eventFilm.publisher, []],
      presentationType: [this.eventFilm.presentationType ? this.eventFilm.presentationType : null, Validators.required],
      languageVersion: [this.eventFilm.languageVersion ? this.eventFilm.languageVersion : null, Validators.required],
      media: [this.eventFilm.media],
    });

    let pattern = null;
    if (this.event.type === 'FILM_RENTRAK') {
      pattern = /^\d+$/;
    } else if (this.event.type === 'FILM_CUSTOM') {
      pattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    }
    if (pattern !== null) {
      this._eventFilmForm.get('typeChildId').setValidators(
        [
          Validators.required,
          Validators.pattern(pattern)
        ]
      );
    }

    if (this.formMode === 'create') {
      this._eventFilmForm
        .get('selectedFilm')
        .valueChanges
        .subscribe(
          () => {
            this._selectedFilmItem = null;
          }
        );
    }
  }

  public generateNewTypeChildId(): void {
    if (this.event.type === 'FILM_CUSTOM') {
      this._eventFilmForm.get('typeChildId').setValue(UUID4());
      this._eventFilmForm.get('typeChildId').updateValueAndValidity();
    }
  }


  public typeaheadOnSelect(e: TypeaheadMatch): void {
    this._selectedFilmItem = e.item;
  }

  public takeFrom(): void {
    const film: Film = this.eventFilms[this._selectedFilmItem.id];
    this._eventFilmForm.get('eventId').setValue(film.eventId);
    this._eventFilmForm.get('title').setValue(film.title);
    this._eventFilmForm.get('description').setValue(film.description);
    this._eventFilmForm.get('directors').setValue(film.directors);
    this._eventFilmForm.get('actors').setValue(film.actors);
    this._eventFilmForm.get('cameraOperators').setValue(film.cameraOperators);
    this._eventFilmForm.get('cutters').setValue(film.cutters);
    this._eventFilmForm.get('books').setValue(film.books);
    this._eventFilmForm.get('ageRating').setValue(film.ageRating);
    this._eventFilmForm.get('runtime').setValue(film.runtime);
    const releaseDate = film.releaseDate ? moment(film.releaseDate) : null;
    this._eventFilmForm.get('releaseDate').setValue(releaseDate ? {
      year: +releaseDate.format('YYYY'),
      month: +releaseDate.format('MM'),
      day: +releaseDate.format('DD'),
    } : null);
    this._eventFilmForm.get('countryOfOrigin').setValue(film.countryOfOrigin);
    this._eventFilmForm.get('creationYear').setValue(film.creationYear);
    this._eventFilmForm.get('spokenLanguage').setValue(film.spokenLanguage);
    this._eventFilmForm.get('subtitleLanguage').setValue(film.subtitleLanguage);
    this._eventFilmForm.get('websiteUrl').setValue(film.websiteUrl);
    this._eventFilmForm.get('genres').setValue(film.genres);
    this._eventFilmForm.get('publisher').setValue(film.publisher);
    this._eventFilmForm.get('media').setValue(film.media);
  }

  submitForm(action?: string) {
    if (this._eventFilmForm.valid) {
      let mode = this.formMode;
      if (action === 'delete') {
        mode = action;
      }

      // releaseDate
      const formData = this._eventFilmForm.value;
      if (formData.releaseDate && isObject(formData.releaseDate)) {
        const releaseDate = new Date(
          formData.releaseDate.year,
          formData.releaseDate.month - 1,
          formData.releaseDate.day,
          0, 0, 0, 0
        );
        formData.releaseDate = moment(releaseDate).format('YYYY-MM-DDTHH:mm:ss');
      }
      this.onFormSubmit.emit({
        mode: mode,
        data: formData
      });
    } else {
      this.validateAllFormFields(this._eventFilmForm);
    }
  }

  cancel(): void {
    this.onCancel.emit();
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        // control.updateValueAndValidity();
        control.markAsDirty();
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  public onClickAgeRating(value): void {
    if (this._eventFilmForm.get('ageRating').value === value) {
      this._eventFilmForm.get('ageRating').setValue(null);
    }
  }

  get eventFilmForm(): FormGroup {
    return this._eventFilmForm;
  }

  get ageRatings() {
    return this._ageRatings;
  }

  get ageRatingsKeys() {
    return this._ageRatingsKeys;
  }

  get presentationTypes() {
    return this._presentationTypes;
  }

  get presentationTypesKeys() {
    return this._presentationTypesKeys;
  }

  get languageVersions() {
    return this._languageVersions;
  }

  get languageVersionsKeys() {
    return this._languageVersionsKeys;
  }

  get filmsAsArray(): Array<Film> {
    return this._filmsAsArray;
  }

  get selectedFilmItem(): any {
    return this._selectedFilmItem;
  }
}
