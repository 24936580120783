<!--<th>{{film?.id}}</th>-->
<td>{{film?.title}}</td>
<td>
  <i class="fa"
     aria-label="Filmbeschreibung vorhanden"
     [ngClass]="{
            'fa-check text-success': film?.description && film.description != null,
            'fa-times-circle text-danger': !film?.description || film.description == null
          }"></i>
</td>
<td>
  <i class="fa"
     aria-label="Eintrag für Regie vorhanden"
     [ngClass]="{
            'fa-check text-success': film?.directors && film.directors.length > 0,
            'fa-times-circle text-danger': !film?.directors || film.directors.length == 0
          }"></i>
</td>
<td>
  <i class="fa"
     aria-label="Eintrag für Darsteller vorhanden"
     [ngClass]="{
            'fa-check text-success': film?.actors && film.actors.length > 0,
            'fa-times-circle text-danger': !film?.actors || film.actors.length == 0
          }"></i>
</td>
<td>
  <i class="fa"
     aria-label="Eintrag für Genre nicht vorhanden"
     [ngClass]="{
            'fa-check text-success': film?.genres && film.genres.length > 0,
            'fa-times-circle text-danger': !film?.genres || film.genres.length == 0
          }"></i>
</td>
<td>
  <i class="fa"
     aria-label="Eintrag für Laufzeit nicht vorhanden"
     [ngClass]="{
            'fa-check text-success': film?.runtime && film.runtime != null && film.runtime > 0,
            'fa-times-circle text-danger': !film?.runtime || film.runtime == null || film.runtime < 1
          }"></i>
</td>
<td>{{film?.spokenLanguage}}</td>
<td>{{film?.creationYear}}</td>
<td>
  <i class="fa"
     aria-label="Eintrag für FSK nicht vorhanden"
     [ngClass]="{
            'fa-check text-success': film?.ageRating && film.ageRating != null,
            'fa-times-circle text-danger': !film?.ageRating || film.ageRating == null
          }"></i>
</td>
<td>
  <i class="fa"
     aria-label="Eintrag für Starttermin vorhanden"
     [ngClass]="{
            'fa-check text-success': film?.releaseDate && film.releaseDate != null,
            'fa-times-circle text-danger': !film?.releaseDate || film.releaseDate == null
          }"></i>
</td>
<td>
  <img *ngIf="film.firstPosterUrl"
       class="img-fluid img--tiny"
       [src]="cdnImageEnvironment.imageUrl(film.firstPosterUrl)"
       alt="Filmposter">

  <!--<img *ngIf="film.firstYoutubeTrailerUrl"
       class="img-fluid img--tiny trailer"
       [src]="film.firstYoutubeTrailerUrl"
       alt="Trailer">-->
</td>
<td>
  <i class="fa"
     aria-label="Trailer nicht vorhanden"
     [ngClass]="{
            'fa-check text-success': film.firstYoutubeTrailerUrl,
            'fa-times-circle text-danger': !film.firstYoutubeTrailerUrl
          }"></i>
</td>
<td>
  <a class="btn btn-primary" role="button" ngbTooltip="{{ 'FORMS.BUTTONS.EDIT' | translate}}"
     [routerLink]="['/','events','detail', film.eventId ,film.id]">
    <i class="fa fa-pencil"></i>
    <span class="text-hide" [translate]="'FORMS.BUTTONS.EDIT'"></span>
  </a>
  <button class="btn btn-danger" ngbTooltip="{{ 'FORMS.BUTTONS.DELETE' | translate}}" (click)="delete()">
    <i class="fa fa-times-circle"></i>
    <span class="text-hide">{{ 'FORMS.BUTTONS.DELETE' | translate}}</span>
  </button>
</td>
